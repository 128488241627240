<template>
	<div id="map_container">
		<!-- <v-overlay opacity="0.5" z-index="1" absolute>
        <v-progress-circular
          indeterminate
          color="primary"
          size="150"
          width="10"
        ></v-progress-circular>
		</v-overlay>-->
		<div id="map"></div>
	</div>
</template>

<script>
import { init } from "@/utils/gmaps.js";
import chroma from "chroma-js";
import { humanFileSize } from '@/utils/index.js';

export default {
	props: {
		center: { type: Object, default: { lat: 40.4165, lng: -3.70256 } },
		zoom: Number,
		value: Array,
		marker: Boolean,
		noEdit: Boolean,
	},
	data() {
		return {
			google: null,
			map: null,
			drawingManager: null,
			prevMarker: null,
			// cubiertas: JSON.parse(JSON.stringify(this.cub)),
		};
	},
	computed: {
		cubiertas() {
			return this.value;
		},
	},
	async mounted() {
		if (this.google == null) {
			this.google = await init();
		}
		await this.$nextTick();
		const map = new this.google.maps.Map(this.$el.querySelector('#map'), {
			center: this.center,
			zoom: this.zoom,
			mapTypeId: "hybrid",
		});
		this.map = map;
		this.map.setTilt(0);

		if (this.marker) {
			this.prevMarker = new this.google.maps.Marker({
				position: this.center,
				map: this.map,
				title: "Marcardor suministro",
			});
		}
		this.cubiertas.splice(0, 0);

		if (this.noEdit) return;
		this.drawingManager = this.getDrawingManager();
		this.drawingManager.setMap(map);
	},
	methods: {
		panTo(coord) {
			this.map.panTo(coord)
		},
		humanFileSize,
		async buscarDireccion(dir) {
			const geocoder = new this.google.maps.Geocoder();
			let coords = await geocoder.geocode({ address: dir })
			coords = coords.results[0].geometry.location
			return { lat: coords.lat(), lng: coords.lng() }
		},
		getDrawingManager() {
			const color = chroma.random();
			const drawingManager = new this.google.maps.drawing.DrawingManager({
				drawingMode: this.google.maps.drawing.OverlayType.POLYGON,
				drawingControl: true,
				drawingControlOptions: {
					position: this.google.maps.ControlPosition.TOP_CENTER,
					drawingModes: [
						this.google.maps.drawing.OverlayType.POLYGON,
						this.google.maps.drawing.OverlayType.MARKER,
					],
				},
				polygonOptions: {
					editable: false,
					strokeColor: color.toString(),
					fillColor: color.toString(),
				},
			});

			const self = this;
			google.maps.event.addListener(
				drawingManager,
				"overlaycomplete",
				function (event) {
					if (event.type == "marker") {
						const pos = {
							latitud: event.overlay.position.lat(),
							longitud: event.overlay.position.lng(),
						};
						self.$emit("changePosition", pos);

						if (self.prevMarker) self.prevMarker.setMap(null);
						self.prevMarker = event.overlay;

						return;
					}

					const coords = event.overlay.getPath().getArray().map( c => ({ lat: c.lat(), lng : c.lng() }))
					// const area = humanFileSize(google.maps.geometry.spherical.computeArea( coords ), 1, 'area')
					const area = parseFloat(google.maps.geometry.spherical.computeArea( coords )).toFixed(3) + ' m2'

					let center = event.overlay
					.getPath()
					.getArray()
					.map((p) => [p.lat(), p.lng()]);

					var x = center.map((xy) => xy[0]);
					var y = center.map((xy) => xy[1]);
					var cx = (Math.min(...x) + Math.max(...x)) / 2;
					var cy = (Math.min(...y) + Math.max(...y)) / 2;

					self.cubiertas.push({
						color: drawingManager.polygonOptions.strokeColor,
						polygon: event.overlay,
						nombre: `Cubierta ${self.cubiertas.length + 1}`,
						center: { lat: cx, lng: cy },
						area,
						id: Math.random(),
					});


					event.overlay.setMap(null);
					let color = chroma.random();
					drawingManager.polygonOptions.strokeColor = color.toString();
					drawingManager.polygonOptions.fillColor = color.toString();
				}
			);
			return drawingManager;
		},
	},
	watch: {
		center: {
			handler(n) {
				try {
					this.map.panTo(n);
				} catch { }
			},
			deep: true,
		},
		zoom: {
			handler(n) {
				try {
					this.map.setZoom(n);
				} catch { }
			},
			deep: true,
		},
		cubiertas: {
			handler(newVal) {
				newVal.forEach((p) => {
					if (Array.isArray(p.polygon)) {
						p.polygon = new this.google.maps.Polygon({
							paths: p.polygon,
							strokeColor: p.color,
							fillColor: p.color,
						});
					}
					p.polygon.setMap(this.map);
				});
			},
			immediate: false,
		},
		// cubiertas(newVal) {
		// 	this.drawingManager.setMap(null);
		// 	this.drawingManager = this.getDrawingManager();
		// 	// this.drawingManager.setMap(this.map);

		// 	newVal.forEach((polygon) => {
		// 		console.log(polygon.polygon.visible);
		// 		// polygon.polygon.setMap(this.map);
		// 	});
		// 	this.$emit(
		// 		"input",
		// 		newVal.filter((item) => item.polygon.visible)
		// 	);
		// },
	},
};
</script>

<style lang="scss" scoped>
#map_container {
	height: 100%;
	#map {
		height: 100%;
	}
}
</style>